import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Alert, Col, Row } from 'react-bootstrap';

const HSRGuidesDamageFormulaPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-damage-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Damage Formula</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_damage.png"
            alt="Damage Formula"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Damage Formula</h1>
          <h2>The damage formula in Honkai: Star Rail explained.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The information found in the guide are based on a great document
          created by <strong>arkkus#4630</strong> and you can find the original{' '}
          <a
            href="https://docs.google.com/document/d/e/2PACX-1vQ9M7q5jLz9DKkTRlsGiB8RGYyPQyhShbuTbrVPQ7-Ke4_U787MfWzr2NjY-KrQo5Ota4Lj3JrFyge9/pub"
            target="_blank"
            rel="noreferrer"
          >
            here.
          </a>
        </p>
        <SectionHeader title="Damage formula" />
        <p>
          The damage formula in Honkai: Star Rail is almost the same as the one
          in Genshin Impact - which isn't that surprising as Star Rail copies a
          lot of systems from Genshin. So, here's how the damage formula looks
          like:
        </p>
        <Alert variant="primary" className="hsr-damage-formula">
          <p>
            Outgoing DMG = <span className="base">Base DMG</span> *{' '}
            <span className="dmg-multi">DMG% Multiplier</span> *{' '}
            <span className="def-multi">DEF Multiplier</span> *{' '}
            <span className="dmg-res">RES Multiplier</span> *{' '}
            <span className="dmg-taken">DMG Taken Multiplier</span> *{' '}
            <span className="toughness">
              Universal DMG Reduction Multiplier
            </span>{' '}
            * <span className="weaken">Weaken Multiplier</span>
          </p>
        </Alert>
        <p>Let's go over the 7 factors that influence the final damage.</p>
        <Row xs={1} xl={2} className="hs-formula-info">
          <Col>
            <div className="box">
              <h5 className="base">Base DMG</h5>
              <p>To find the Base DMG value you need to use another formula:</p>
              <h6>
                Base DMG = (Skill Multiplier + Extra Multiplier) * Scaling
                Attribute + Extra DMG
              </h6>
              <p>Where:</p>
              <ul>
                <li>
                  <strong>Skill Multiplier</strong> = this is the percentage
                  value you can find in the skill description (Deal DMG equal to
                  XX%),
                </li>
                <li>
                  <strong>Extra Multiplier</strong> = this appears only on some
                  skills, like Dan Heng's Ultimate that deals additional damage
                  to slowed enemies,
                </li>
                <li>
                  <strong>Scaling Attribute</strong> = this is the attribute the
                  skill scales off - in most cases it's ATK,
                </li>
                <li>
                  <strong>Extra DMG</strong> = this is the flat additional
                  damage that appears on some skills.
                </li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5 className="dmg-multi">DMG% Multiplier</h5>
              <p>
                All DMG% is added together and grouped into one place in the DMG
                equation. The equation is as follows:
              </p>
              <h6>
                DMG% Multiplier = 100% + Elemental DMG% + All Type DMG% + DoT
                DMG% + Other DMG%
              </h6>
              <p>
                Remove DMG% multipliers if they are not relevant to the
                calculation. For example, if Hook has 38.9% Fire DMG and gains
                20% DMG against a Burning/Bleeding enemy from “Woof! Walk Time!”
                then her total DMG% multiplier is 158.9% against a Burning or
                Bleeding enemy, but only 138.9% against a non-Burning or
                Bleeding enemy.
              </p>
            </div>
          </Col>
        </Row>
        <Row xs={1} xl={2} className="hs-formula-info">
          <Col>
            <div className="box">
              <h5 className="def-multi">DEF Multiplier</h5>
              <p>
                DEF Multiplier scales with the level difference between
                characters, similar to Genshin Impact. The higher the attacker
                level compared to defender level, the greater the DEF
                Multiplier. The DEF related equations for a normal defender are:
              </p>
              <h6>
                DEF Multiplier = 100% - [DEF / (DEF + 200 + 10 * Attacker
                Level)]
              </h6>
              <p>To find the DEF, you need to use another formula:</p>
              <h6>
                DEF = Base DEF * (100% + DEF% - (DEF Reduction + DEF Ignore)) +
                Flat DEF
              </h6>
              <p>DEF cannot go below 0.</p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5 className="dmg-res">RES Multiplier</h5>
              <p>The RES Multiplier operates by the following equation:</p>
              <h6>RES Multiplier = 100% - (RES% - RES PEN%)</h6>
              <p>
                All enemies appear to have a base 20% RES to all elements unless
                they have innate weaknesses or resistances. If an enemy is weak
                to an element, then they have 0% RES to that element. If an
                enemy is resistant to an element, then they have 40% RES to that
                element.
              </p>
              <p>RES cannot go below -100% or above 90%.</p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5 className="dmg-taken">DMG Taken Multiplier</h5>
              <p>
                This portion of the equation functions differently from Genshin
                Impact, where it would normally be a part of DMG%. In Honkai:
                Star Rail, the DMG Taken Multiplier operates by the following
                equation:
              </p>
              <h6>
                DMG Taken Multiplier = 100% + Elemental DMG Taken% + All Type
                DMG Taken%
              </h6>
              <p>
                Example sources that affect this multiplier include Welt’s
                Ultimate as well as Sampo’s Ultimate.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5 className="toughness">Universal DMG Reduction Multiplier</h5>
              <p>This portion of the equation is as follows:</p>
              <p>
                <strong>
                  Universal DMG Reduction Multiplier = 100% * (1 - DMG
                  Reduction_1) * (1 - DMG Reduction_2) * ...
                </strong>
              </p>
              <p>
                When an enemy has Toughness, they have 10% Universal DMG
                Reduction, which is reduced to 0% when broken. Note this
                multiplier stacks multiplicative with other sources.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5 className="weaken">Weaken Multiplier</h5>
              <p>
                The Weaken Multiplier is related to the Weaken status effect
                that can be applied by Natasha and Sampo’s passive Trace. At
                present, it is only applicable when calculating enemy damage,
                but may be relevant in the future if enemies can inflict Weaken:
              </p>
              <p>
                <strong>Weaken Multiplier = 100% - Weaken%</strong>
              </p>
            </div>
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Calculating damage" />
        <p>
          Now that all the factors of the damage formula are explained, we can
          accurately calculate damage against same-level enemies.
        </p>
        <p>Let's use Tingyun as our test subject - here are her stats:</p>
        <StaticImage
          src="../../../images/starrail/generic/damage_1.jpg"
          alt="Guide"
        />
        <p>Now let's list everything that affects the final damage:</p>
        <ul>
          <li>1062 ATK</li>
          <li>25.8% Lightning DMG</li>
          <li>4p Musketeer: +10% Basic DMG</li>
          <li>Lvl 2 Basic Skill Multiplier: 60%</li>
          <li>Lvl 50 Tingyun against Lvl 50 enemies: 50% DEF Multiplier</li>
          <li>Enemy is not weak to Lightning: 80% RES Multiplier</li>
          <li>
            Enemy is not affected by any DMG Taken debuffs: 100% DMG Taken
            Multiplier
          </li>
          <li>Enemy is not broken: 90% Toughness Multiplier</li>
        </ul>
        <p>
          And now it's time to input those number into the damage formula above:
        </p>
        <Alert variant="primary" className="hsr-damage-formula">
          <p>
            Expected DMG = <span className="base">(60% * 1062)</span> *{' '}
            <span className="dmg-multi">(100% + 25.8% + 10%)</span> *{' '}
            <span className="def-multi">50%</span> *{' '}
            <span className="dmg-res">80%</span> *{' '}
            <span className="dmg-taken">100%</span> *{' '}
            <span className="toughness">90%</span>
          </p>
          <p>Expected DMG = 311.5 = 312</p>
        </Alert>
        <StaticImage
          src="../../../images/starrail/generic/damage_2.jpg"
          alt="Guide"
        />
        <p>It matches!</p>
        <p>
          Now let’s try against this enemy when they’re broken, which changes
          the Toughness multiplier to 100%.
        </p>
        <Alert variant="primary" className="hsr-damage-formula">
          <p>
            Expected DMG = <span className="base">(60% * 1062)</span> *{' '}
            <span className="dmg-multi">(100% + 25.8% + 10%)</span> *{' '}
            <span className="def-multi">50%</span> *{' '}
            <span className="dmg-res">80%</span> *{' '}
            <span className="dmg-taken">100%</span> *{' '}
            <span className="toughness">100%</span>
          </p>
          <p>Expected DMG = 346.1 = 346</p>
        </Alert>
        <StaticImage
          src="../../../images/starrail/generic/damage_3.jpg"
          alt="Guide"
        />
        <p>It matches again!</p>
        <p>
          Now let’s try against an enemy weak to Lightning, which changes the
          RES multiplier to 100%.
        </p>
        <Alert variant="primary" className="hsr-damage-formula">
          <p>
            Expected DMG = <span className="base">(60% * 1062)</span> *{' '}
            <span className="dmg-multi">(100% + 25.8% + 10%)</span> *{' '}
            <span className="def-multi">50%</span> *{' '}
            <span className="dmg-res">100%</span> *{' '}
            <span className="dmg-taken">100%</span> *{' '}
            <span className="toughness">90%</span>
          </p>
          <p>Expected DMG = 389.4 = 389</p>
        </Alert>
        <StaticImage
          src="../../../images/starrail/generic/damage_4.jpg"
          alt="Guide"
        />
        <p>
          Welp, we're off by 1. That's most likely caused by a rounding error as
          the character's attributes values are displayed as integer.
        </p>
        <p>Let's use another character now - here are her stats:</p>
        <StaticImage
          src="../../../images/starrail/generic/damage_5.jpg"
          alt="Guide"
        />
        <p>Now let's list everything that affects the final damage:</p>
        <ul>
          <li>1432 ATK</li>
          <li>18.6% Quantum DMG (15.6% from Planar Sphere, 3% from Traces)</li>
          <li>
            She's under the effect of Last Tile Claim buff(S2) which gives +15%
            DMG (this is considered All Elemental DMG% by the game’s
            calculation)
          </li>
          <li>4p Musketeer: +10% Basic DMG</li>
          <li>Lvl 3 Basic Skill Multiplier: 70%</li>
          <li>Lvl 50 Qingque against Lvl 50 enemies: 50% DEF Multiplier</li>
          <li>Enemy is not weak to Quantum: 80% RES Multiplier</li>
          <li>
            Enemy is not affected by any DMG Taken debuffs: 100% DMG Taken
            Multiplier
          </li>
          <li>Enemy is not broken: 90% Toughness Multiplier</li>
        </ul>
        <Alert variant="primary" className="hsr-damage-formula">
          <p>
            Expected DMG = <span className="base">(70% * 1432)</span> *{' '}
            <span className="dmg-multi">(100% + 18.6% + 15% + 10%)</span> *{' '}
            <span className="def-multi">50%</span> *{' '}
            <span className="dmg-res">80%</span> *{' '}
            <span className="dmg-taken">100%</span> *{' '}
            <span className="toughness">90%</span>
          </p>
          <p>Expected DMG = 518.2 = 518</p>
        </Alert>
        <StaticImage
          src="../../../images/starrail/generic/damage_6.jpg"
          alt="Guide"
        />
        <p>It matches again!</p>
        <p>
          Now let’s try against an enemy weak to Quantum, which changes the RES
          multiplier to 100%.
        </p>
        <Alert variant="primary" className="hsr-damage-formula">
          <p>
            Expected DMG = <span className="base">(70% * 1432)</span> *{' '}
            <span className="dmg-multi">(100% + 18.6% + 15% + 10%)</span> *{' '}
            <span className="def-multi">50%</span> *{' '}
            <span className="dmg-res">100%</span> *{' '}
            <span className="dmg-taken">100%</span> *{' '}
            <span className="toughness">90%</span>
          </p>
          <p>Expected DMG = 647.8 = 648</p>
        </Alert>
        <StaticImage
          src="../../../images/starrail/generic/damage_7.jpg"
          alt="Guide"
        />
        <p>And it matches again!</p>
        <p>
          The document linked at the top of the page has more examples in case
          you need more.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Stats" />
        <p>
          While calculating the stats of a character, you need to know that the
          formula works a bit different than in other games - the 'bonus' stats
          you're getting from Light Cones are actually combined with the base
          stats of the character and only then other increases are applied.
        </p>
        <ul>
          <li>
            <strong>HP Total</strong> = (Character Base HP + LC Base HP) * (1 +
            HP%) + Flat HP
          </li>
          <li>
            <strong>ATK Total</strong> = (Character Base ATK + LC Base ATK) * (1
            + ATK%) + Flat ATK
          </li>
          <li>
            <strong>DEF Total</strong> = (Character Base DEF + LC Base DEF) * (1
            + DEF%) + Flat DEF
          </li>
          <li>
            <strong>Speed Total</strong> = Character Base Speed * (1 + Speed%) +
            Flat Speed
          </li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesDamageFormulaPage;

export const Head: React.FC = () => (
  <Seo
    title="Damage Formula | Honkai: Star Rail | Prydwen Institute"
    description="The damage formula in Honkai: Star Rail explained."
  />
);
